import React from 'react';
import Layout from '../components/Layout';
import { withIntl } from '../i18n';
import Landing from '../templates/Landing';

const host = process.env.GATSBY_HOST;

const cloud = {
  header: {
    title: 'cloudHeaderTitle',
    subTitle: 'cloudHeaderSubTitle',
    image: 'cloud.png',
    button1: {
      text: 'registration',
      subText: 'registrationSubText',
      link: `${host}/auth2/single_account?redirect_uri=${host}/&enter=signup`,
      event: 'CLOUD_get_started',
      id: 'sign_up_cloud'
    },
    button2: {
      text: 'orderDemo',
      link: '/form/live-demo',
      event: 'CLOUD_request_demo',
      id: 'request_demo_cloud'
    }
  },
  content: [
    {
      title: 'cloudContentHostTitle',
      subTitle: 'cloudContentHostSubTitle',
      image: 'Hosting-1.png'
    },
    {
      title: 'cloudContentDeployTitle',
      subTitle: 'cloudContentDeploySubTitle',
      image: 'Deploy.png',
    },
    {
      title: 'cloudContentVersTitle',
      subTitle: 'cloudContentVersSubTitle',
      image: 'Versioning-1.png',
    },
    {
      title: 'cloudContentScaleTitle',
      subTitle: 'cloudContentScaleSubTitle',
      image: 'Horizontal-scaling-1.png',
    },
    {
      title: 'cloudContentPerformanceTitle',
      subTitle: 'cloudContentPerformanceSubTitle',
      image: 'Performance-cloud-1.png',
    },
    {
      title: 'cloudContentSecurityTitle',
      subTitle: 'cloudContentSecuritySubTitle',
      image: 'Security-cloud-1.png',
    }
  ],
  demo: {
    event: 'digital_core_start'
  }
};


const Cloud = (props) => (
  <Layout {...props}>
    <Landing
      data={cloud}
      page={'cloud'}
    />
  </Layout>
);


export default withIntl(Cloud);

